import { Button, Result } from 'antd';
import { navigate } from 'gatsby';
import React from 'react';

import { Box, Section } from '../components/layout';
import { StaticPageId } from '../data';
import { useLinkResolver } from '../hooks';

const NotFoundPage: React.FC = () => {
  const resolveLink = useLinkResolver();

  return (
    <Section
      viewportHeight={true}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Box justify="center">
            <Button
              type="primary"
              onClick={() => navigate(resolveLink(StaticPageId.Home))}
              aria-label="Home"
            >
              Home
            </Button>
          </Box>
        }
      />
    </Section>
  );
};

export default NotFoundPage;
